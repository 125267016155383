<template>
  <main class="step-page6">
    <Heading title="Potwierdź dane przed wysłaniem" />
    <section class="step6 step step--sixth">
      <div class="step6__container">
        <div class="step6__content">
          <div class="step6__summary" data-aos="fade-in">
            <p class="step6__title">
              Podsumowanie zgłoszenia:
            </p>
            <div class="e-table">
              <div class="e-table__row">
                <div class="e-table__col">
                  <div class="e-table__label">
                    <router-link :to="{ name: 'step1' }" class="e-table__link">
                      <img class="step4__map" src="@/assets/images/edit.svg" alt="icon">
                    </router-link>
                    <p>Zainteresowany:</p>
                  </div>
                  <!-- / e-table__label -->

                </div>
                <!-- / e-table__col -->

                <div class="e-table__col" v-if="Object.keys(selectedProducts).length">
                  <p v-for="(product, index) in selectedProducts" :key="index">{{ product.amount }} x {{ products.find(item => index == item.id).name }} - {{ product.material }}</p>
                </div>
                <!-- / e-table__col -->

              </div>
              <!-- / e-table__row -->

              <div class="e-table__row">
                <div class="e-table__col">
                  <div class="e-table__label">
                    <router-link :to="{ name: 'step2' }" class="e-table__link">
                      <img class="step4__map" src="@/assets/images/edit.svg" alt="icon">
                    </router-link>
                    <p>Planowany projekt:</p>
                  </div>
                  <!-- / e-table__label -->

                </div>
                <!-- / e-table__col -->

                <div class="e-table__col" v-if="Object.keys(selectedPurpose).length">
                  <p>{{ purposes.find(item => selectedPurpose.id == item.id).name }} {{ 'option' in selectedPurpose ? '- ' + selectedPurpose.option : '' }}</p>
                </div>
                <!-- / e-table__col -->

              </div>
              <!-- / e-table__row -->

              <div class="e-table__row">
                <div class="e-table__col">
                  <div class="e-table__label">
                    <router-link :to="{ name: 'step3' }" class="e-table__link">
                      <img class="step4__map" src="@/assets/images/edit.svg" alt="icon">
                    </router-link>
                    <p>Planowany okres montażu:</p>
                  </div>
                  <!-- / e-table__label -->

                </div>
                <!-- / e-table__col -->

                <div class="e-table__col">
                  <p>Montaż planowany na {{ selectedDate }}</p>
                </div>
                <!-- / e-table__col -->

              </div>
              <!-- / e-table__row -->

              <div class="e-table__row">
                <div class="e-table__col">
                  <div class="e-table__label">
                    <router-link :to="{ name: 'step4' }" class="e-table__link">
                      <img class="step4__map" src="@/assets/images/edit.svg" alt="icon">
                    </router-link>
                    <p>Obiekt znajduje się w:</p>
                  </div>
                  <!-- / e-table__label -->

                </div>
                <!-- / e-table__col -->

                <div class="e-table__col">
                  <p>{{ selectedLocalisation }}</p>
                </div>
                <!-- / e-table__col -->

              </div>
              <!-- / e-table__row -->

            </div>
            <!-- / e-table -->

          </div>
          <!-- / step6__summary -->

          <div class="step6__personal" data-aos="fade-in" data-aos-delay="250">
            <p class="step6__title">
              Twoje dane kontaktowe:
            </p>
            <p>{{ personalData.name + ' ' + personalData.surname }}</p>
            <p>{{ personalData.phone }}</p>
            <p class="email">{{ personalData.email }}</p>
            <p>{{ personalData.company }}</p>
            <p>{{ personalData.street + ' ' + personalData.number }}</p>
            <p>{{ personalData.postcode + ' ' + personalData.city }}</p>
          </div>
          <!-- / step6__personal -->

        </div>
        <!-- / step6__content -->

      </div>
      <!-- / step6__container -->

      <Bottom type="actions">
        <Button :path="'step5'" title="Edytuj zapytanie" :classes="'e-button--secondary'" />
        <Button :loading="loading" title="Wyślij zapytanie" :classes="loading ? 'e-button--disabled' : 'e-button--primary'" @clicked="sendForm()" />
      </Bottom>
    </section>
    <!-- / step6  -->
    
  </main>
</template>

<script>
import Date from "@/components/Date.vue";
import Bottom from "@/components/Bottom.vue";
import Button from "@/components/Button.vue";
import Heading from "@/components/Heading.vue";

export default {
  name: "Step6",
  data() {
    return {
      loading: false
    }
  },
  components: {
    Date,
    Bottom,
    Button,
    Heading
  },
  computed: {
    products() {
      return this.$store.state.products;
    },
    selectedProducts() {
      return this.$store.state.selectedProducts;
    },
    purposes() {
      return this.$store.state.purposes;
    },
    selectedPurpose() {
      return this.$store.state.selectedPurpose;
    },
    selectedDate() {
      return this.$store.state.selectedDate;
    },
    selectedLocalisation() {
      return this.$store.state.selectedLocalisation;
    },
    personalData() {
      return this.$store.state.personalData;
    }
  },
  methods: {
    sendForm() {
      if(!this.loading) {
        this.loading = true;
  
        this.$store.dispatch('sendForm').then((resp) => {
          if ('id' in resp.data) {
            this.loading = false;
            this.$router.push({ path: 'summary' }).catch(() => { });
          }
        }).catch((err) => {
          this.loading = false;
          console.log(err);
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.step6 {
  background-color: $color-bg-alt;

  &__container {
    @media #{$xl} {
      @include flex(center, center, nowrap);
      min-height: 504px;
    }
  }

  &__content {
    margin-bottom: 30px;
    
    @media #{$xl} {
      @include flex(flex-start, flex-start, nowrap);
    }
  }

  &__title {
    @extend %big-label;
    color: $color-primary;
    margin-bottom: 20px;
  }

  &__summary {
    margin-bottom: 30px;

    @media #{$xl} {
      margin-bottom: 0;
    }
  }

  &__personal {
    @extend %product-name;
    color: $color-text;

    @media #{$xl} {
      padding-left: 43px;
    }
    
    p:not(.step6__title) {
      margin-bottom: 15px;
    }

    p {
      &.email {
        word-break: break-all;
      }
    }
  }
}

  .e-table {
    @extend %product-name;
    color: $color-text;

    @media #{$xl} {
      width: calc(100% - 50px);
    }

    &__row {
      border-bottom: 1px solid $color-line;
      padding: 15px 0;

      @media #{$md} {
        @include flex(flex-start, flex-start, nowrap);
      }

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        border: none;
        padding-bottom: 0;
      }

      .e-table__col {
        
        &:first-of-type {
          flex: 150px 0 0;
        }

        &:nth-child(2) {
          padding-left: 30px;
          flex: auto;
        }
      }
    }

    &__link {
      font-size: 0;

      img, svg {
        height: 22px;
        width: 22px;
      }
    }

    &__label {
      @include flex(flex-start, flex-start, nowrap);

      p {
        margin-left: 9px;
        font-weight: 700;
      }
    }
  }
</style>